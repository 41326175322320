<template>
    <ion-page data-pageid="home">
        <ThanhTieuDeVue TieuDe="Danh sách chuyến đi">
            <template #after><div></div></template>
        </ThanhTieuDeVue>
        <ion-content>
            <div slot="fixed" style="width: 100%" class="white--bg">
                <div class="px-3">
                    <DxCalendar
                        class="mt-3"
                        id="calendar-container"
                        v-model="model"
                        :first-day-of-week="1"
                        width="100%"
                        :onInitialized="onInitialized"
                        :onValueChanged="onValueChanged"
                    ></DxCalendar>
                </div>
            </div>
            <DxList
                style="margin-top: 355px"
                :data-source="data"
                height="calc(100vh - 420px)"
                class="list-style"
                no-data-text="Không có dữ liệu! Vuốt để tải lại"
                :onItemClick="onItemClick"
                :pullRefreshEnabled="true"
                :activeStateEnabled="false"
            >
                <template #item="{ data }">
                    <div class="row justify-center full-width">
                        <div class="container-md item">
                            <div class="row align-center">
                                <div class="text-xs-center pr-2 primary--text">
                                    <i class="mdi mdi-bus font-24"></i>
                                    <div class="font-16 font-bold">
                                        {{ data.bienKiemSoat }}
                                    </div>
                                </div>
                                <div
                                    class="pl-2"
                                    style="border-left: 1px solid #ddd"
                                >
                                    <div
                                        class="row align-center"
                                        style="white-space: normal"
                                    >
                                        <div class="font-16 font-bold">
                                            {{ data.noiDi }}
                                        </div>
                                        <i
                                            class="
                                                mdi mdi-arrow-right-bold
                                                font-20
                                                primary--text
                                            "
                                        ></i>
                                        <div class="font-16 font-bold">
                                            {{ data.noiDen }}
                                        </div>
                                    </div>
                                    <div class="row align-center pt-2">
                                        <i
                                            class="
                                                mdi mdi-timelapse
                                                primary--text
                                                font-24
                                                pr-1
                                            "
                                        ></i>
                                        <div class="font-16 font-bold">
                                            {{
                                                moment(
                                                    data.gioXuatBenKeHoach
                                                ).format("HH:mm DD/MM/yyyy")
                                            }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </DxList>
        </ion-content>
    </ion-page>
</template>
<script>
import ThanhTieuDeVue from "../../../src/components/_Common/ThanhTieuDe";
import ChuyenDi from "../../../class/ChuyenDi";
import moment from "moment";
import { DxCalendar, DxList } from "devextreme-vue";
import { IonContent, IonPage } from "@ionic/vue";
export default {
    components: {
        ThanhTieuDeVue,
        DxCalendar,
        DxList,
        IonContent,
        IonPage,
    },
    data() {
        return {
            model: new Date(),
            LoiHeThong: true,
            data: null,
        };
    },
    methods: {
        onInitialized() {
            this.autoScroll("dx-calendar-today");
        },
        onValueChanged() {
            this.autoScroll("dx-calendar-selected-date");
            this.getData();
        },
        autoScroll(element) {
            //Tự động hiển thị ngày đang chọn
            setTimeout(() => {
                let EleToDay = document.getElementsByClassName(element);
                let EleBody =
                    document.getElementsByClassName("dx-calendar-body");
                if (EleToDay.length > 0 && EleBody.length > 0) {
                    EleBody[0].scrollTop = EleToDay[0].offsetTop - 40;
                }
            }, 500);
        },
        onItemClick(e) {
            if (!e.itemData) {
                throw new Error("Không có dữ liệu trong e.itemData");
            }
            let query = {
                guidChuyenDi: e.itemData.guidChuyenDi,
                guidXe: e.itemData.guidXe,
                maChuyenDi: e.itemData.maChuyenDi,
                guidDoanhNghiep: e.itemData.guidDoanhNghiep,
            };
            this.$router.push({
                path: "/Bao-Cao/so-do-cho",
                query: query,
            });
            localStorage.setItem("ThongTinChuyenDi", JSON.stringify(query));
        },
        getData() {
            let danhSachChuyenDi = new ChuyenDi().layDanhSachChuyenDi(
                moment(this.model).format("yyyy-MM-DD")
            );
            this.data = danhSachChuyenDi;
        },
    },
    created() {
        this.getData();
    },
};
</script>
<style lang="scss" scoped>
#calendar-container {
    border: 1px solid $border;
    border-radius: $border_radius;
}
#calendar-container.dx-calendar {
    min-height: unset !important;
}
// .list-item-style {
//     box-shadow: 3px 3px 7px rgb(0 0 0 / 18%);
//     border-radius: $border_radius;
// }
/* #calendar-container.dx-calendar .dx-calendar-body .dx-widget {
    top: -120px;
} */
</style>
<style scoped>
>>> #calendar-container .dx-calendar-body {
    overflow-y: scroll;
    overflow-x: hidden;
    scroll-behavior: smooth;
}
>>> #calendar-container thead {
    position: sticky;
    top: 0;
    background: white;
}
>>> .list-style.dx-list .dx-empty-message {
    text-align: center !important;
    font-size: 16px;
    padding-top: 10%;
}
>>> .list-style .dx-empty-message {
    width: 50%;
    margin: 0 auto;
}
</style>
<style lang="scss" scoped>
.item {
    border-radius: $border_radius;
    box-shadow: $box_shadow;
}
</style>